(function() {
    function applyInlineStyles(element, styles) {
        Object.keys(styles).forEach(key => {
            element.style.setProperty(key, styles[key], 'important');  // Verwende !important, um Stile zu erzwingen
        });
    }

    function initLogos() {
        const logoA = document.querySelector('img[alt="Logo A"]');
        const logoB = document.querySelector('img[alt="Logo B"]');

        if (!logoA) {
            console.warn('Logo A nicht gefunden');
            return;
        }

        const showBothLogos = new URLSearchParams(window.location.search).get('ref') === 'wbs';

        if (showBothLogos && logoB) {
            // Beide Logos anzeigen und korrekt positionieren
            const logoContainer = document.createElement('div');
            logoContainer.style.position = 'absolute';
            logoContainer.style.top = '10px';  // Zentriere von oben
            logoContainer.style.left = '50%';  // Zentriere horizontal
            logoContainer.style.transform = 'translateX(-50%)';  // Korrektur, um mittig zu sein
            logoContainer.style.display = 'flex';
            logoContainer.style.justifyContent = 'center';
            logoContainer.style.alignItems = 'center';
            logoContainer.style.gap = 'min(600px, 16vw)';  // Dynamischer Abstand, max. 400px
            logoContainer.style.padding = '0';
            logoContainer.style.margin = '0';
            logoContainer.style.maxWidth = '100%';

            // Füge den Container hinzu
            logoA.parentNode.insertBefore(logoContainer, logoA);
            logoContainer.appendChild(logoA);
            logoContainer.appendChild(logoB);

            // Setze die Stile für beide Logos (Standardgröße)
            applyInlineStyles(logoA, {
                opacity: '1',
                width: '110px',
                height: 'auto',
                display: 'block',
                margin: '0',
            });

            applyInlineStyles(logoB, {
                opacity: '1',
                width: '110px',
                height: 'auto',
                display: 'block',
                margin: '0',
            });

            // Media Query für mobile Geräte (maximale Breite von 768px)
            const mediaQuery = window.matchMedia("(max-width: 768px)");
            if (mediaQuery.matches) {
                // Verkleinere die Logos auf mobilen Geräten
                applyInlineStyles(logoA, {
                    width: '100px',  // Verkleinerte Breite für mobile
                });

                applyInlineStyles(logoB, {
                    width: '100px',  // Verkleinerte Breite für mobile
                });
            }
        } else {
            // Nur Logo A anzeigen, Logo B ausblenden
            applyInlineStyles(logoA, {
                opacity: '1',
                display: 'block',
                width: '100px',
                height: 'auto',
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '20px',
            });

            if (logoB) {
                applyInlineStyles(logoB, {
                    opacity: '0',
                    display: 'none',
                });
            }
        }
    }

    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', initLogos);
    } else {
        initLogos();
    }
})();



window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

document.addEventListener('DOMContentLoaded', function() {
    const checkAndActivate = () => {
        const activeSlide = document.querySelector('.flow-slide-active');
        if (activeSlide && activeSlide.dataset.slideNumber === '3') { // Angenommen, die Folie 3 hat das data-slide-number Attribut mit dem Wert '3'
            const birthdateField = document.getElementById('birthdate');
            const submitButton = activeSlide.querySelector('[data-flow-next]'); // oder der spezifische Submit-Button Ihrer Folie
            
            function isValidDate(d) {
                return d instanceof Date && !isNaN(d);
            }

            function checkAge() {
                const today = new Date();
                const birthdate = new Date(birthdateField.value);
                const age = today.getFullYear() - birthdate.getFullYear();
                const monthDifference = today.getMonth() - birthdate.getMonth();
                const dayDifference = today.getDate() - birthdate.getDate();

                if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
                    age--;
                }

                return age >= 18;
            }

            birthdateField.addEventListener('input', function() {
                if (isValidDate(new Date(birthdateField.value)) && checkAge()) {
                    submitButton.disabled = false;
                } else {
                    submitButton.disabled = true;
                }
            });
        }
    };

    // Initial check when the page is loaded
    checkAndActivate();

    // Check whenever a new slide becomes active
    const observer = new MutationObserver(checkAndActivate);
    observer.observe(document.body, { attributes: true, childList: true, subtree: true });
});



function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}



const input = document.createElement('input');
input.type = 'text';
input.name = 'Unique_ID';
input.id = 'Unique_ID';
input.variable  = 'Unique_ID';
input.setAttribute('data-label', 'Unique_ID');
input.value = generateGUID();
input.style = "display:none;"

document.addEventListener('DOMContentLoaded', () => {
    const form = document.getElementsByTagName('form')[0];
    form.appendChild(input);
});